<template>
  <validation-observer ref="observer">
    <v-form
      ref="form"
      lazy-validation
      :disabled="isSubmitting"
      @submit.prevent="onSubmit"
    >
      <h4 class="text-overline">
        <v-icon left>
          mdi-airplane
        </v-icon>
        Aircraft
      </h4>
      <v-row class="mt-0">
        <v-col
          cols="12"
          :sm="showAircraftDetails ? 4 : 12"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-autocomplete
              v-model="arrival.aircraft_data"
              :items="aircraftList"
              :loading="isLoadingAircraft"
              :search-input.sync="aircraftSearchQuery"
              no-filter
              @change="onAircraftChange"
              hide-no-data
              item-text="registration"
              item-value="registration"
              label="Aircraft Registration"
              placeholder="Type aircraft registration..."
              auto-select-first
              return-object
              outlined
              single-line
              :error-messages="errors"
              required
            >
              <template v-slot:selection="{ attr, on, item }">
                <span v-text="item.registration" />
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.registration" />
                  <v-list-item-subtitle v-if="item.icao_type">
                    {{ item.icao_type }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </validation-provider>
        </v-col>
        <v-col
          v-if="showAircraftDetails"
          cols="6"
          sm="4"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-text-field
              outlined
              label="Aircraft Type"
              required
              v-model="arrival.aircraft_type"
              :error-messages="errors"
              autocomplete="off"
            />
          </validation-provider>
        </v-col>

        <v-col
          v-if="showAircraftDetails"
          cols="6"
          sm="4"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-text-field
              outlined
              label="Aircraft MTOW"
              required
              v-model="arrival.aircraft_mtow"
              :error-messages="errors"
              autocomplete="off"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <h4 class="text-overline">
        <v-icon left>
          mdi-airplane-takeoff
        </v-icon>
        Arriving From
      </h4>
      <v-row class="mt-0">
        <v-col
          cols="12"
          sm="4"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-autocomplete
              v-model="arrival.origin_data"
              :items="airfieldList"
              :loading="isLoadingAirfield"
              :search-input.sync="airfieldSearchQuery"
              no-filter
              @change="onAirfieldChange"
              hide-no-data
              item-text="title"
              item-value="title"
              label="Arriving From"
              placeholder="Type airfield name or code..."
              auto-select-first
              return-object
              outlined
              single-line
              :error-messages="errors"
              required
            >
              <template v-slot:selection="{ attr, on, item }">
                <span v-text="item.title" />
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.title" />
                  <v-list-item-subtitle v-if="item.code">
                    {{ item.code }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-dialog
            ref="dateDialog"
            v-model="datePickerModal"
            :return-value.sync="arrival.date"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <v-text-field
                  outlined
                  label="Arrival Date"
                  prepend-inner-icon="mdi-calendar"
                  required
                  v-model="formattedArrivalDate"
                  :error-messages="errors"
                  autocomplete="off"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </validation-provider>
            </template>
            <v-date-picker
              v-model="arrival.date"
              scrollable
              :min="minDate"
              :max="maxDate"
            >
              <v-spacer />
              <v-btn
                text
                color="grey"
                @click="datePickerModal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.dateDialog.save(arrival.date);
                  datePickerModal = false;
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-dialog
            ref="timeDialog"
            v-model="timePickerModal"
            :return-value.sync="arrival.time"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
              >
                <v-text-field
                  outlined
                  label="Arrival Time"
                  prepend-inner-icon="mdi-clock-outline"
                  required
                  v-model="arrival.time"
                  :error-messages="errors"
                  autocomplete="off"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </validation-provider>
            </template>
            <v-time-picker
              v-if="timePickerModal"
              v-model="arrival.time"
              full-width
              format="24hr"
              :allowed-minutes="allowedTimeStep"
            >
              <v-spacer />
              <v-btn
                text
                color="grey"
                @click="timePickerModal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.timeDialog.save(arrival.time);
                  timePickerModal = false;
                "
              >
                OK
              </v-btn>
            </v-time-picker>
          </v-dialog>
        </v-col>
      </v-row>

      <h4 class="text-overline">
        <v-icon left>
          mdi-account
        </v-icon>
        Pilot In Command
      </h4>
      <v-row class="mt-0">
        <v-col
          cols="12"
          sm="6"
          md="3"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-text-field
              outlined
              label="Name of PIC"
              required
              v-model="arrival.captain_name"
              :error-messages="errors"
              autocomplete="off"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-text-field
              outlined
              label="Persons On Board"
              type="number"
              min="1"
              max="999"
              required
              hint="Including PIC"
              persistent-hint
              v-model="arrival.pob"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-text-field
              outlined
              label="Email of PIC"
              required
              v-model="arrival.captain_email"
              :error-messages="errors"
              autocomplete="off"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="3"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-text-field
              outlined
              label="Phone of PIC"
              required
              v-model="arrival.captain_phone"
              :error-messages="errors"
              autocomplete="off"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pt-0">
          <div v-if="pilotBriefingUrl">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
            >
              <v-checkbox
                v-model="arrival.pilot_briefing_accepted"
                required
                :error-messages="errors"
                class="mt-1"
              >
                <template v-slot:label>
                  <div @click.stop="">
                    I have read, understood, and will abide by any requirements
                    within the
                    <a
                      target="_blank"
                      :href="pilotBriefingUrl"
                    >airfield's pilot briefing</a>.
                  </div>
                </template>
              </v-checkbox>
            </validation-provider>
          </div>
          <div v-if="showTerms">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
            >
              <v-checkbox
                v-model="arrival.terms_accepted"
                required
                :error-messages="errors"
                class="mt-1"
              >
                <template v-slot:label>
                  <div @click.stop="">
                    I accept the AirfieldHub
                    <a
                      href="#"
                      @click.prevent="termsModal = true"
                    >terms</a>
                    and
                    <a
                      href="#"
                      @click.prevent="privacyModal = true"
                    >privacy policy</a>.
                  </div>
                </template>
              </v-checkbox>
            </validation-provider>
            <terms-dialog
              :show="termsModal"
              :on-close="onTermsDialogClose"
            />
            <privacy-dialog
              :show="privacyModal"
              :on-close="onPrivacyDialogClose"
            />
          </div>
        </v-col>
      </v-row>
      <v-row class="justify-center mb-2">
        <v-btn
          color="primary"
          rounded
          type="submit"
          :loading="isSubmitting"
        >
          {{ submitButtonText }}
        </v-btn>
      </v-row>
    </v-form>
  </validation-observer>
</template>

<script>
import {required} from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode
} from "vee-validate";
import PrivacyDialog from "./PrivacyDialog.vue";
import TermsDialog from "./TermsDialog.vue";
import lookupAircraft from "@/repositories/AircraftRepository";
import lookupAirfields from "@/repositories/AirfieldRepository";
import debounce from "@/tools/debounce";
import {DateTime} from "luxon";

function _newArrival() {
  return {
    captain_name: "",
    captain_email: "",
    captain_phone: "",
    aircraft_data: null,
    aircraft_reg: "",
    aircraft_type: "",
    aircraft_mtow: "",
    origin: "",
    origin_data: null,
    pob: 1,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 10),
    time: "",
    date_time: ""
  };
}

export default {
  name: "ArrivalForm",
  props: {
    onSuccess: {
      type: Function,
      default: () => console.error("onSuccess must be provided!")
    },
    submitButtonText: {type: String, default: "Save"},
    pilotBriefingUrl: {type: String, default: null},
    showTerms: {type: Boolean, default: true}
  },

  created() {
    setInteractionMode("eager");

    extend("required", {
      ...required,
      message: "Required"
    });
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    PrivacyDialog,
    TermsDialog
  },

  data() {
    return {
      isSubmitting: false,
      datePickerModal: false,
      timePickerModal: false,
      termsModal: false,
      privacyModal: false,
      minDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),
      maxDate: new Date(
        Date.now() -
        new Date().getTimezoneOffset() * 60000 +
        60000 * 60 * 24 * 120
      )
        .toISOString()
        .substring(0, 10),
      arrival: _newArrival(),
      isLoadingAircraft: false,
      isLoadingAirfield: false,
      showAircraftDetails: false,
      aircraftSearchQuery: null,
      airfieldSearchQuery: null,
      aircraftList: [],
      airfieldList: [],
    };
  },

  computed: {
    formattedArrivalDate() {
      return this.arrival.date
        ? DateTime.fromISO(this.arrival.date).toLocaleString(
          DateTime.DATE_MED_WITH_WEEKDAY
        )
        : "";
    },
  },

  methods: {
    allowedTimeStep: m => m % 5 === 0,
    onSubmit: function () {
      this.isSubmitting = true;
      this.$refs.observer
        .validate()
        .then(async isValid => {
          if (isValid) {
            await this.onSuccess(this.arrival);
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    onTermsDialogClose() {
      this.termsModal = false;
    },
    onPrivacyDialogClose() {
      this.privacyModal = false;
    },
    onAircraftChange() {
      // console.log("CHANGED Aircraft", this.arrival.aircraft_data);
      this.arrival.aircraft_reg = this.arrival.aircraft_data?.registration || "";
      this.arrival.aircraft_type = this.arrival.aircraft_data?.icao_type || "";
      this.arrival.aircraft_mtow = this.arrival.aircraft_data?.mtow || "";

      this.showAircraftDetails = !!this.arrival.aircraft_data
    },
    onAirfieldChange() {
      // console.log("CHANGED Airfield", this.arrival.origin_data);
      this.arrival.origin = this.arrival.origin_data?.title || "";
    }
  },
  watch: {
    aircraftSearchQuery: debounce(async function (val) {
      this.isLoadingAircraft = true;
      this.aircraftList = await lookupAircraft(val);
      this.isLoadingAircraft = false;
    }, 350),

    airfieldSearchQuery: debounce(async function (val) {
      this.isLoadingAirfield = true;
      this.airfieldList = await lookupAirfields(val);
      this.isLoadingAirfield = false;
    }, 350),
  },

};
</script>
